<template>

  <div class="root-container">

    <div :class="homeContainer" id="home">
      <div :class="homeContainerLeft">
        <div class="flex-col">
          <div>
            <div class="flex-row">
              <div
                class="svg-logo"
                v-html="getRawIcon('RAREIUM_LOGO_LETTER')"
              />
              <h1 class="home-title-text">
                aredex
              </h1>
            </div>

            <div>
              <h2 class="home-header-text">
                Digital tokens physically backed by strategic metals.
              </h2>
              <div class="buy-container">
                <div
                  class="svg-arbitrum-logo"
                  v-html="getRawIcon('ARBITRUM_LOGO')"
                />
                <div>
                  Raredex is Live on Arbitrum.
                </div>
                <div
                  class="swap-text-box"
                  @click.stop="openSwap()"
                >
                  <span class="swap-text">Buy Now</span>
                </div>
              </div>
              <div class="home-header-subtext">
                Raredex offers an efficient and convenient means to own high quality industry grade rare earth and technology metals via blockchain tokenization. These metals tokens are backed by 1 kilogram of high-grade, industry-standard rare earth metals, securely stored in a bonded storage facility in Germany. Metals under management are accompanied by comprehensive source-of-origin supply chain data, ensuring transparency and accountability. By holding Raredex tokens, you gain direct ownership rights to the underlying asset.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="homeContainerRight === 'home-container-a-right-desktop'"
        :class="homeContainerRight"
      >
        <div class="diamond-container">
          <Shape />
        </div>
      </div>
    </div>

    <div :class="homeContainer">
      <div :class="homeContainerLeft">
        <div class="home-header-text-container">
          <h2 class="home-header-text">
            Community Token Launch
          </h2>
          <div>
          Pioneer, prospector, explorer, OG. We are so early! Just as those before us prospected for gold to build the modern world, prospectors of today are seeking access to rare metals to build the future! Mobile phones, batteries, renewable energy, nuclear power, defence, medical, and semiconductors are all reliant on rare earth and technology metals.
          <br />
          <br />
          This non-investment token will power Rare'DEX' (Decentralized EXchange) enabling all participants to buy and sell without limitations. This pilot engages you with our community and will provide access to the rare earth metals simply by holding the community token.
          </div>
          <div>
            <br />
            <b>The Benefits of Community Engagement</b>
            <ul>
              <li>
                Early access to rare earth metal backed tokens, before the general public.
              </li>
              <li>
                Direct ownership of rare earth metals by staking and participation. 
              </li>
              <li>
                Participate in a community and interact with experts in the space.
              </li>
              <li>
                An early seat at a table normally reserved for governments or the elite.
              </li>
            </ul>
          </div>
          <div class="link-section">
            <div
              class="swap-text-info"
              @click="gotoTab('community-token')"
            >
              <span>Info On Community Token</span>
            </div>
            <div
              @click="gotoUrl('https://t.me/raredex')"
              class="telegram-link"
            >
              <div
                v-html="getRawIcon('TELEGRAM')"
                class="telegram-icon"
              />
              <div>Join us on telegram</div>
            </div>
          </div>
        </div>
        <div class="home-register-container">
<!--
          <div
            @click="openLogin"
            v-if="userInfo.authorized == false"
            class="home-register-button"
          >
            Sign Up / Login
          </div>
          <div
            @click="gotoTab('exchange')"
            v-if="userInfo.authorized == true"
            class="home-register-button"
          >
            Access Exchange.
          </div>
-->
        </div>
      </div>
      <div
        v-if="homeContainerRight === 'home-container-a-right-desktop'"
        :class="homeContainerRight"
      >
        <Launcher style="width:100%" />
      </div>
    </div>

    <div :class="homeContainer">
      <div :class="homeContainerLeft">
        <h2 class="home-header-text">
          Community Token Breakdown.
        </h2>
        <div :class="pieContainer">
          <PieChart />
        </div>
      </div>
      <div :class="homeContainerRight">
        <div class="flex-col chart-key-container">
          <div class="flex-row">
            <div class="metals-slice" />
            <div class="slice-text">
              33% <b>Metals</b> - We strongly believe in the future of rare earth and technology metals, by building a store of tradable liquid metals we will position ourselves at the head of the ecosystem with the most advanced service in the world.
            </div>
          </div>
          <div class="flex-row">
            <div class="liquidity-slice" />
            <div class="slice-text">
              33% <b>Liquidity</b> - To launch the DEX (Decentralized EXchange) we need to enable trading pairs. Purchases of the community token will kickstart this process with trading fees going right back into the community in the form of rare earth metal token rewards.
            </div>
          </div>
          <div class="flex-row">
            <div class="operations-slice" />
            <div class="slice-text">
              34% <b>Kickstart</b> - We will use the remainder of the funds to help us build out the roadmap and launch this project to the moon. We have the first build complete, join us for the last mile as we enable the future of technology.
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="homeContainer">

      <div>
        <h2 class="home-header-text">
          Metals Tokenomics
        </h2>

        <div>
          We implement custom token logic, &quot;tokenomics&quot;, to enable the specific use case of tradeable rare earth metal pairs. With each metal token being fully backed by physical assets, this is necessary to account for specifics such as storage costs. The tokenomics are designed to provide value through real asset ownership, diversified exposure, and community-driven participation.
        </div>

        <div>
          <ul>
            <li>1 metal token = 1 kilogram of physical metal, fully backed with physical delivery options.</li>
            <li>Fractional Ownership, previously only large OTC sales were possible.</li>
            <li>Safe custody in a bonded storage facility in Germany.</li>
            <li>Minimal storage fees are built into the contract, simplifying ownership.</li>
            <li>An index token option available that tracks a diversified basket of metals.</li>
            <li>A non backed community token allows early access and staking options.</li>
          </ul>
        </div>

      </div>

    </div>

    <div id="metals" />

    <h1 class="title-text">
      Rare Earth & Technology Metals
    </h1>

    <div id="metals" :class="homeContainer">

      <D3
        :class="d3GraphContainer"
        :data="chartData"
        :symbol="selectedSymbol"
        :tokens="tokens"
        axisColor="#8eaec0"
        hideXAxis=true
        hideYAxis=true
        hideControls=true
        yTicksIn="5"
        xTicksIn="6"
        strokeWidth="5"
        timeFrame="fiveYear"
        multiGraph=false
        @passback-symbols="updateSymbols"
      />

      <div
        class="home-container-a-box"
      >
        <div class="appreciation-container">

          <div class="five-year-text">

            <h2 class="home-header-text">
              Price Discovery.
            </h2>

            <Transition name="slide-fade" :duration="{ enter: 300, leave: 300 }">

              <div v-if="tokens[selectedSymbol]">
                <span class="five-year-percent-change-text">
                  The chart illustrates the  
                </span>
                <span class="five-year-percent-change">
                  {{fiveYearChange}}% 
                </span>
                <span class="five-year-percent-change-text">
                  change in {{ tokens[selectedSymbol].name }} ({{ tokens[selectedSymbol].symbol }}) over the past 5 years. Due to the critical supply shortage we are entering price discovery in strategically important rare earth elements. At Raredex you can easily purchase individual rare earths or soon the rare earth index token (REI), which gives you exposure to all rare earths and strategically important technology metals in one easy to buy and hold vehicle. As the Chart shows we are in a bullish consolidation phase, these uncorrelated assets offer tremendous potential both in the short and long term.
                </span>
              </div>

            </Transition>

          </div>

          <Transition name="slide-fade" :duration="{ enter: 300, leave: 300 }">
            <div
              v-if="tokens[selectedSymbol]"
              @click="showMetalModal = true"
              class="home-register-button"
            >
              Graph Another Metal.
            </div>
          </Transition>

        </div>

      </div>

    </div>

    <div class="home-container-a-box">

    </div>

    <Carousel
      id="gallery"
      v-model="currentSlide"
      wrap-around="true"
      items-to-show="1.0"
      autoplay="80000000000000"
      translition="1000"
      pause-autoplay-on-hover="true"
    >
      <Slide
        v-for="(i,j) in Object.entries(tokens).filter(([key, value]) => value.symbol !== 'REI')"
        :key="j"
      >
        <div
          :class="homeContainer"
        >
          <div :class="metalContainer">
            <div class="metal-image">
              <img :src="i[1].image" class="metal-img" >
            </div>
          </div>
          <div class="flex-col metal-text-c">
            <div class="metal-text-container">
              <h1 class="home-header-text">             
                {{i[1].name}} ({{ getPts(i[1].symbol) }})
              </h1>
              <div class="flex-row-center">
                <div
                  class="symbolicon"
                  v-html="getRawIcon(i[1].icon)"
                />
                <div>
                  <b>{{i[1].symbol}}</b>
                </div>
              </div>
            </div>
            <div class="metal-text">
              {{i[1].text}}
            </div>
          </div>
        </div>
      </Slide>

      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>

    <div id="roadmap" />

    <div class="home-container-a-box">

      <h2 class="home-header-text">
        Roadmap
      </h2>

      <div :class="roadmapContainer">
        <div :class="roadmapContent">
          <div class="roadmap-bubble" data-quarter="Q1">
              <div class="bubble-content">Q1<br>Token Launch</div>
          </div>
          <div class="roadmap-text">
            In 2024 we stealth built the project. Now we are excited to launch the First fully backed tokenized Rare Earth and Technology metals and community token.
          </div>
        </div>
        <div :class="roadmapContent">
          <div class="roadmap-bubble" data-quarter="Q2">
              <div class="bubble-content">Q2<br>Build</div>
          </div>
          <div class="roadmap-text">
            Continue the build of the decentralized exchange, enable more partnerships in the metals industry and enable liquidity provision for trading pairs.
          </div>
        </div>
        <div :class="roadmapContent">
          <div class="roadmap-bubble" data-quarter="Q3">
              <div class="bubble-content">Q3<br>DEX Launch</div>
          </div>
          <div class="roadmap-text">
            The launch of our DEX will enable instant trade settlement 24/7 anywhere in the world. It will complete this phase of the project and enable the success of our community.
          </div>
        </div>
        <div :class="roadmapContent">
          <div class="roadmap-bubble" data-quarter="Q4">
              <div class="bubble-content">Q4<br>Grow</div>
          </div>
          <div class="roadmap-text">
            Our future plans to grow are ambitious and include enabling multiple metal brokerages, on demand delivery, building our own strategic stockpile of metals and listing more critical commodities.
          </div>
        </div>
      </div>
    </div>

    <div id="faq" />

    <div class="home-container-a-box">

      <h2 class="home-header-text">
        FAQs
      </h2>

      <div :class="faqContainer"> <!--roadmapContainer--> 
	  
	      <h3 class="faq-header-text">
		      General Questions
	      </h3>
		
        <div :class="faqContent">   <!--roadmapContent--> 
          <div class="faq-question">  <!--"roadmap-bubble" data-quarter="Q1"-->
		    What are rare earths and technology metals, and why are they important?
          </div>
          <div class="faq-answer"> <!--roadmap-text--> 
		    These are Strategic Metals, the backbone of manufacturing in the 21st century. Their importance can never be overstated as they are critical to all nations&apos; economic prosperity and military capabilities.
          </div>
        </div>
		
        <div :class="faqContent">
          <div class="faq-question">
		    What metals can I purchase on Raredex.io?
          </div>
          <div class="faq-answer">
		        We offer the technology metals and rare earths that are most relevant:
            <ul>
              <li>Technology metals: Gallium, Germanium, Rhenium and Indium</li>
              <li>Rare Earth Metals: Dysprosium, Neodymium, Praseodymium and Terbium.</li>
            </ul>
          </div>
        </div>
		
        <div :class="faqContent">
          <div class="faq-question">
		        Why should I consider purchasing tokenized rare earths and technology metals instead of traditional assets like gold or stocks?
          </div>
          <div class="faq-answer">
		         Strategic Metals have consistently been outperforming precious metals and all major stock indexes. The market is purely supply and demand driven.
          </div>
        </div>
		
        <div :class="faqContent">
          <div class="faq-question">
		        Why should I consider purchasing tokenized rare earths and technology metals tokens instead of other tokens?
          </div>
          <div class="faq-answer">
		        Raredex metal tokens are asset backed and backed by commodities that are fast replacing oil and gas in importance globally.
          </div>
        </div>
		
      </div>

      <div :class="faqContainer">
        <h3 class="faq-header-text">
          Investment Process
        </h3>
      
      
        <div :class="faqContent">
          <div class="faq-question">
            Is there a minimum outlay required?
          </div>
          <div class="faq-answer">
            No.
          </div>
        </div> 

        <div :class="faqContent">
          <div class="faq-question">
            How does Raredex ensure the authenticity and quality of the metals?
          </div>
          <div class="faq-answer">
            Raredex purchase directly from Europe&apos;s most reputable supplier of strategic metals. Our inventory is in the chain of custody, REACH certified, and in original packaging from producers with batch numbers.
          </div>
        </div> 

        <div :class="faqContent">
          <div class="faq-question">
            Can I hold my purchases in physical form, or is it purely digital?
          </div>
          <div class="faq-answer">
            It is both, as you have your digital Raredex token which is backed by the metal(s) in our bonded storage facility in Germany.
          </div>
        </div>     
      </div>

      <div :class="faqContainer">
        <h3 class="faq-header-text">
          Market Insights
        </h3>
        
        <div :class="faqContent">
          <div class="faq-question">
            What drives the demand for rare earths and technology metals?
          </div>
          <div class="faq-answer">
            All modern technology, electric cars, wind turbines, solar, space exploration, aviation, medical devices, nuclear power, computer chips, semiconductors.
          </div>
        </div>
        
        <div :class="faqContent">
          <div class="faq-question">
            How does geopolitical tension impact the supply and pricing of rare earths and technology metals?
          </div>
          <div class="faq-answer">
            The political landscape increasingly plays a role as China is the dominant market leader in the industry. At time of writing China has an outright ban on Gallium and Germanium to the US and are restricting the export of all rare earths as well.
          </div>
        </div>
      </div>

      <div :class="faqContainer">
        <h3 class="faq-header-text">
          Storage and Security
        </h3>

        <div :class="faqContent">
          <div class="faq-question">
            Where are the rare earths and technology metals stored?
          </div>
          <div class="faq-answer">
            They are stored in a bonded storage facility, in Germany.
          </div>
        </div>

        <div :class="faqContent">
          <div class="faq-question">
            What measures does Raredex take to secure my metals?
          </div>
          <div class="faq-answer">
            Raredex tokens are 100% assay (metal) backed and stored in a bonded storage facility in Germany.
          </div>
        </div>
      </div>

      <div :class="faqContainer">
          <h3 class="faq-header-text">
            Trading and Liquidity
          </h3>

          <div :class="faqContent">
            <div class="faq-question">
              Are there any fees or taxes involved when buying or selling Raredex tokens?
            </div>
            <div class="faq-answer">
              Our storage facility is a tax and duty-free zone so no taxes on the purchase or sale of the metals. There is a bid/ask spread on the purchase and sale just like precious metals.
            </div>
          </div>
      </div>

      <div :class="faqContainer">
        <h3 class="faq-header-text">
          Risks and Challenges
        </h3>

        <div :class="faqContent">
          <div class="faq-question">
            What are the risks of purchasing rare earths and technology metals?
          </div>
          <div class="faq-answer">
            The demand for strategic metals is increasing and there are even predictions there will soon not be enough to supply the energy transition, however all purchases come with risk. The primary risk is substitution where a new and better technology could replace an existing one. Thankfully, all of our metals have multiple applications which mitigates the risk in that regard.
          </div>
        </div>
     </div>
     <div :class="faqContainer">
        <h3 class="faq-header-text">
          Sustainability
        </h3>

        <div :class="faqContent">
          <div class="faq-question">
            Are rare earths and technology metals a sustainable investment?
          </div>
          <div class="faq-answer">
            Yes, 100%; in fact one could almost consider rare earths as waste recycling.
            The reason is that rich rare earth seams do not occur naturally in the earth&apos;s crust and are surfaced as a byproduct of mining another raw material.
            Until we found utility for them, they were considered waste. It is estimated there are 500,000 abandoned coal mines in North America alone with mine &#8220;tailings&#8221; that can now be harvested and utilised.
          </div>
        </div>
      </div>

    </div>

    <div>
<!--
      <div class="bottom-register-container">
        <div>
          Sign up or login to start your Rare Earth Journey.
        </div>
        <div
          @click="openLogin"
          v-if="userInfo.authorized == false"
          class="home-register-button"
        >
          Register.
        </div>
        <div
          @click="gotoTab('exchange')"
          v-if="userInfo.authorized == true"
          class="home-register-button"
        >
          Access Exchange.
        </div>
      </div>
-->
    </div>

  </div>

  <div
    v-if="serverConfig.view != 'mobile'"
    class="home-news-container-desktop"
  >
    <div
      v-for="(i,j) in news"
      class="home-news-absolute"
    >
      <Transition
        :duration="300"
        name="news-slide-fade"
      >
        <div
          v-if="displayNewsItem == i.id && i.title"
          class="flex-row-center home-news-container-inner"
        >
          <img
            class="home-news-image"
            :src="'../public/images/news_item_' + i.id + '.png'"
          >
          <div :class="newsText">
            <div>
              <b>{{ i.title }}</b>
            </div>
            <div>
              {{ i.text }}
            </div>
          </div>
        </div>
      </Transition>
    </div>
  </div>

  <Transition>
    <CModal
      v-if="showMetalModal"
      color="#5d328d"
      width="small"
      secondaryColor="linear-gradient(338deg,#ff7cf5,#46b6ff)"
      @close="showMetalModal = false"
    >
      <template #header>
        <div> {{ localize("TEXT_SELECT_METAL") }} </div>
      </template>
      <template #body>
        <!-- Start select metal column headers -->
        <div class="home-container-item">
          <div class="flex-row-center">
            <div class="symbolicon">
              &nbsp;
            </div>

            <div class="home-container-metal-name">
              Metal
            </div>
            <div class="home-container-a-symbol">
              &nbsp;
            </div>
          </div>
          <div class="flex-row-center-space">
            <div class="home-container-a-price">
              <span>Price / kg</span>
            </div>
            <div class="home-container-a-ytd-header">
              <span>Annual % change</span>
            </div>
          </div>
        </div>
        <!-- End select metal column headers -->
        <div
          v-for="(i,k) in tokens"
          class="home-container-item"
          @click="getChartData(i.symbol)"
        >
          <div class="flex-row-center">
            <div
              class="symbolicon"
              v-html="getRawIcon(i.icon)"
            />
            <div class="home-container-metal-name">
              {{ i.name }}
            </div>
            <div class="home-container-a-symbol">
              {{k}}
            </div>
          </div>
          <div class="flex-row-center-space">
            <div class="home-container-a-price">
              <span>${{ i.price }}</span>
            </div>
            <div
              v-if="i.ytd > 0"
              class="home-container-a-ytd-pos"
            >
              <span>{{ i.ytd }} %</span>
            </div>
            <div
              v-else-if="i.ytd < 0"
              class="home-container-a-ytd-neg"
            >
              <span>{{ i.ytd }} %</span>
            </div>
            <div
              v-else
              class="home-container-a-ytd"
            >
              <span>{{ i.ytd }} %</span>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div />
      </template>
    </CModal>
  </Transition>

  <Transition>
    <CModal
      v-if="showSwapModal"
      color="#5d328d"
      width="medium"
      secondary-color="linear-gradient(338deg,#ff7cf5,#46b6ff)"
      background-color="#333"
      @close="showSwapModal = false"
    >
      <template #header>
        <div> {{ localize("TEXT_SWAP") }} </div>
      </template>
      <template #body>
        <div class="swap-container">
          <Mint
            :sell-token="sellToken"
            :sell-tokens="sellTokens"
            :buy-token="buyToken"
            :buy-tokens="buyTokens"
            @hide-modal-event="showSwapModal = false"
            @get-orders="getOrders()"
          />
<!--
            :buy-tokens="mockBuyTokens"
-->
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>
  </Transition>

</template>

<script>
import {Carousel, Navigation, Pagination, Slide} from "vue3-carousel";
import {emit, inject, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {translationStore} from "@/stores/translation";
import Shape from "./Shape.vue";
import PieChart from "./PieChart.vue";
import Launcher from "./Launcher.vue";
import Mint from "./Mint.vue";
import D3 from "./D3.vue";
import Axios from "axios";
import {iconStore} from "@/stores/icon";

export default {
    "name": "Home",
    "components": {
        Carousel,
        Shape,
        PieChart,
        Slide,
        Pagination,
        Navigation,
        D3,
        Mint,
        Launcher
    },
    setup (props, {emit}) {

        const translation = translationStore(),
            web3 = inject("web3"),
            eBus = inject("eBus"),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            showMetalModal = ref(false),
            showSwapModal = ref(false),
            roadmapContainer = ref("roadmap-container-desktop"),
            roadmapContent = ref("flex-col"),
            faqContainer = ref("faq-container-desktop"),
            faqContent = ref("flex-col"),
            raredexToken = ref({
                icon: "RAREDEX_RDX_ICON",
                symbol: "RDX",
                name: "Raredex",
                address: "0x78d2247cAb34d540195F334c8ef38D87DD8fD4b8",
                balance: 0,
                price: 1
            }),
            sellToken = ref("USDC"),
            sellTokens = ref({
                "USDC":{
                    icon: "USDC_ICON",
                    symbol: "USDC",
                    name: "USD Circle",
                    address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
                    balance: 0,
                    price: 1
                }
            }),
            buyToken = ref("RDX"),
            buyTokens = ref({}),
            mockBuyTokens = ref({
                "RGA":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RGA",
                    name: "Gallium",
                    address: "0x41b13447596710be62d583d7107a6b6fdb5b88c6",
                    balance: 0,
                    price: 755
                },
                "RGE":{
                    icon: "RAREDEX_RGE_ICON",
                    symbol: "RGE",
                    name: "Germanium",
                    address: "0xbd9040791beb52a7a9eaf6cb7f2165942805ca9b",
                    balance: 0,
                    price: 4018.86
                },
                "RDX":{
                    icon: "RAREDEX_RDX_ICON",
                    symbol: "RDX",
                    name: "Raredex",
                    address: "0x78d2247cAb34d540195F334c8ef38D87DD8fD4b8",
                    balance: 0,
                    price: "789",
                }
            }),
            route = useRoute(),
            router = useRouter(),
            d3GraphContainer = ref(),
            selectedSymbol = ref(),
            symbols = ref({}),
            currentSlide = ref(),
            icon = iconStore(),
            currentIndex = ref(0),
            email = ref(""),
            chartData = ref({}),
            fiveYearChange = ref(),
            homeContainer = ref(""),
            homeContainerLeft = ref(""),
            homeContainerRight = ref(""),
            homeHeaderText = ref("home-header-text"),
            homeContentText = ref("home-content-text"),
            homeHeaderTextBlack = ref("home-header-text-black"),
            homeContentTextBlack = ref("home-content-text-black"),
            metalContainer = ref(""),
            pieContainer = ref("pie-container-desktop"),
            getAllERC20Tokens = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getAllERC20Tokens`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        for (let i = 0; i < response.data.length; i++) {

                            const tid = response.data[i].symbol;

                            if (tid && response.data[i].address.startsWith("0x")) {

                                buyTokens.value[tid] = response.data[i];
                                buyTokens.value[tid].balance = 0;

                            }

                        }

                        // raredexToken
                        buyTokens.value['RDX'] = raredexToken.value;
                        buyTokens.value['RDX'].balance = 0;

                        tokens.value['REI'] = {
                            icon: "RAREDEX_RDX_ICON",
                            symbol: "REI",
                            color: "#2FB2FF",
                            name: "Rare Earth Index",
                            text: "",
                            image: "/public/images/Rhenium.png",
                            opacity: 0,
                            price: 1942.20,
                            ytd: 20.89
                        }

                })

            },
            openSwap = function () {

                showSwapModal.value = true;

            },
            updateSymbols = function (e) {

                symbols.value = e;

            },
            openLogin = async function (symbol) {

                    eBus.emit(
                        "app-event",
                        {
                            "action": "showLoginModal",
                            "data": ""
                        }
                    );

            },
            getChartData = async function (symbol) {

                if (tokens.value[selectedSymbol.value] == symbol) {

                    return;

                }

                selectedSymbol.value = 'react';

                let url;

                if (symbol === "RDY") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=dysprosiumoxid';

                } else if (symbol === "RPR") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=praseodymoxid';

                } else if (symbol === "RTB") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=terbiumoxid';

                } else if (symbol === "RGA") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=gallium';

                } else if (symbol === "RGE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=germanium';

                } else if (symbol === "RHF") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=hafnium';

                } else if (symbol === "RIN") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=indium';

                } else if (symbol === "RRE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=rhenium';

                } else if (symbol === "RND") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=neodymoxid';

                } else {

                    return;

                }

                Axios.get(
                    url,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        showMetalModal.value = false;

                        const result = { usd: {}, eur: {} };
                        Object.keys(response.data).forEach((date) => {

                            result['usd'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].usd);
                            result['eur'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].eur);

                        });

                        chartData.value[symbol] = result;



                        selectedSymbol.value = symbol;

                        const dates = Object.keys(chartData.value[symbol].usd);

                        if (dates.length === 0) {

                            return null;

                        }

                        const mostRecentDate = dates.reduce((recentDate, currentDate) => {

                            return recentDate > currentDate ? recentDate : currentDate;

                        });

                        fiveYearChange.value = chartData.value[symbol].usd[mostRecentDate].toFixed(0);

                });

            },
            tokens = ref({
                
                "RDY":{
                    icon: "RAREDEX_RDY_ICON",
                    symbol: "RDY",
                    pts: "Dy",
                    color: "#2FB2FF",
                    name: "Dysprosium",
                    text: "Dysprosium is a chemical element with the symbol Dy and atomic number 66. It is a rare earth element with a metallic silver luster. Dysprosium is seldom encountered as a free element in nature and is usually found in minerals such as xenotime, dysprosium-yttrium fluorite, gadolinite and euxenite. 32Dy makes up about 0.06% of the Earth's crust.",
                    image: "/public/images/Dysprosium.png",
                    opacity: 0,
                    price: 578,
                    ytd: -11.48
                },
                "RND":{
                    icon: "RAREDEX_RND_ICON",
                    symbol: "RND",
                    pts: "Nd",
                    color: "#2FB2FF",
                    name: "Neodymium",
                    text: "Neodymium is a rare earth element that is found in the earth’s crust. Its metallic element number is 60 and its chemical symbol is Nd. It is categorized as a lanthanide. Neodymium has been used since the early 1950s in different products to create magnets with higher magnetic properties than those made with iron or other metals.",
                    image: "/public/images/Neodymium.png",
                    opacity: 0,
                    price: 123,
                    ytd: -41
                },
                "RPR":{
                    icon: "RAREDEX_RPR_ICON",
                    symbol: "RPR",
                    pts: "Pr",
                    color: "#2FB2FF",
                    name: "Praseodymium",
                    text: "Praseodymium is a chemical element belonging to the rare earth group of metals. It has the atomic number 59 and is represented by the symbol Pr on the periodic table. Discovered in 1885 by Carl Auer von Welsbach, praseodymium has a silvery-white color when in its purest form. Strong praseodymium-iron-boron magnets are commonly used in smartphones, wind turbines, and electric motors due to their superior strength and lightweight properties. ",
                    image: "/public/images/Praseodymium.png",
                    opacity: 0,
                    price: 122,
                    ytd: -38
                },
                "RTB":{
                    icon: "RAREDEX_RTB_ICON",
                    symbol: "RTB",
                    pts: "Rb",
                    color: "#2FB2FF",
                    name: "Terbium",
                    text: "Terbium is a chemical element with the symbol Tb and atomic number 65. In metal form, terbium is a silvery-white rare earth metal. In its oxide form, in which it is sold commercially, terbium is a black-brown powder. Terbium does not occur naturally and is considered one of the “rarest” of Rare Earths. Some critical use cases are in LCD screens, fiber optics, alloys and nuclear reactors.",
                    image: "/public/images/Terbium.png",
                    opacity: 0,
                    price: 2230.40,
                    ytd: -44.58
                },
                "RGA":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RGA",
                    pts: "Ga",
                    color: "#2FB2FF",
                    name: "Gallium",
                    text: "Gallium is a chemical element with the symbol Ga and atomic number 31. Gallium is a soft, silvery metal, and Elemental gallium is a liquid at temperatures greater than 29.76C (85.57F) (slightly above room temperature). Solid gallium alloys are used in optics, electronics, and nuclear engineering because of their non-toxicity and resistance to neutron radiation and beta decay.",
                    image: "/public/images/Gallium.png",
                    opacity: 0,
                    price: 755,
                    ytd: 17.95
                },
                "RGE":{
                    icon: "RAREDEX_RGE_ICON",
                    symbol: "RGE",
                    pts: "Ge",
                    color: "#2FB2FF",
                    name: "Germanium",
                    text: "Germanium is a chemical element with the symbol Ge and atomic number 32. It is a grayish-white metalloid with a lustrous appearance and is hard and brittle. A relatively rare element, germanium is a metal that has been used in a variety of applications throughout history. In its purest form, germanium is used as a semiconductor in transistors and other electronic devices. Nowadays, just about every industry uses computers.",
                    image: "/public/images/Germanium.png",
                    opacity: 0,
                    price: 2839.40,
                    ytd: 21.11
                },
                "RHF":{
                    icon: "RAREDEX_RHF_ICON",
                    symbol: "RHF",
                    color: "#2FB2FF",
                    pts: "Hf",
                    name: "Hafnium",
                    text: "Hafnium is a chemical element with the symbol Hf and atomic number 72. Found in zirconium minerals, it’s a shiny, silvery, ductile metal that resists corrosion due to the formation of a tough, impenetrable oxide film on its surface. This strategic metal is used in a variety of industries and applications, including Nuclear Reactors, Aerospace, Chemical applications, Microelectronics and much more",
                    image: "/public/images/Hafnium.png",
                    opacity: 0,
                    price: 5298.90,
                    ytd: 16.20
                },
                "RIN":{
                    icon: "RAREDEX_RIN_ICON",
                    symbol: "RIN",
                    pts: "In",
                    color: "#2FB2FF",
                    name: "Indium",
                    text: "Indium is a rare, malleable, and easily workable metallic element with the atomic number 49 and the symbol In. It has a silvery-lustrous gray appearance and is considered one of the least abundant elements in earth’s crust. Indium is an essential and versatile element with various uses across many industries. Indium has put its unique properties to work in consumer electronics, industrial manufacturing, mining exploration, medical technology, metallurgy, and transportation.",
                    image: "/public/images/Indium.png",
                    opacity: 0,
                    price: 561.60,
                    ytd: 26.97
                },
                "RRE":{
                    icon: "RAREDEX_RRE_ICON",
                    symbol: "RRE",
                    pts: "Re",
                    color: "#2FB2FF",
                    name: "Rhenium",
                    text: "Rhenium is an extremely rare and dense chemical element with atomic number 75, which makes it a part of the transitional metals group. It has a silver-grey appearance and melts at an incredibly high temperature of 3,186 degrees Celsius / 5,767 Fahrenheit. Its unique features have caused rhenium to be used in many different areas, from military and aerospace technology to medical research.",
                    image: "/public/images/Rhenium.png",
                    opacity: 0,
                    price: 1942.20,
                    ytd: 20.89
                }

            }),
            slideTo = function (v) {

                currentSlide.value = v;

            },
            getIcon = function (i) {

                if (serverConfig.visualMode.value === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}/`}).catch((err) => {

                    throw err;

                });

            },
            gotoUrl = function (url) {

                window.open(url, "_blank");

            },
            getPts = function (str) {

                if (str.length < 2) {
                    return str;
                }
                const lastTwo = str.slice(-2);
                return str.slice(0, -2) + lastTwo[0].toUpperCase() + lastTwo[1].toLowerCase();

            },
            getNews = function () {

                news.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getNews`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            news.push(response.data[n]);

                        }

                    });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    homeContainer.value = "home-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"
                    pieContainer.value = "pie-container-desktop"
                    roadmapContainer.value = "roadmap-container-desktop"
                    roadmapContent.value = "bubble-desktop"
                    faqContainer.value = "faq-container-desktop"
                    faqContent.value = "faq-desktop"

                } else if (serverConfig.view === "laptop") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    homeContainer.value = "home-container-desktop"
                    metalContainer.value = "metal-container-desktop"
                    pieContainer.value = "pie-container-desktop"
                    roadmapContainer.value = "roadmap-container-desktop"
                    roadmapContent.value = "bubble-desktop"
                    faqContainer.value = "faq-container-desktop"
                    faqContent.value = "faq-desktop"

                } else if (serverConfig.view === "tablet") {

                    homeContainerRight.value = "home-container-a-right-desktop"
                    homeContainerLeft.value = "home-container-a-left-desktop"
                    homeContainer.value = "home-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"
                    pieContainer.value = "pie-container-desktop"
                    roadmapContainer.value = "roadmap-container-mobile"
                    roadmapContent.value = "bubble-desktop"
                    faqContainer.value = "faq-container-desktop"
                    faqContent.value = "faq-mobile"

                } else if (serverConfig.view === "mobile") {

                    homeContainerRight.value = "home-container-a-right-mobile"
                    homeContainerLeft.value = "home-container-a-left-mobile"
                    homeContainer.value = "home-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"
                    pieContainer.value = "pie-container-mobile"
                    roadmapContainer.value = "roadmap-container-mobile"
                    roadmapContent.value = "bubble-mobile"
                    faqContainer.value = "faq-container-mobile"
                    faqContent.value = "faq-mobile"

                } else {

                    homeContainerRight.value = "home-container-a-right-mobile"
                    homeContainerLeft.value = "home-container-a-left-mobile"
                    homeContainer.value = "home-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"
                    pieContainer.value = "pie-container-mobile"
                    roadmapContent.value = "bubble-mobile"
                    faqContainer.value = "faq-container-mobile"
                    faqContent.value = "faq-mobile"

                }

            };

        onMounted(() => {

            if (route.path == 'login/') {

                showLoginModal.value = true;

            }

            getAllERC20Tokens();
            updateView();

            let run = function() {

              setInterval(() => {

                currentIndex.value++;

                if (currentIndex.value == 7) {

                  currentIndex.value = 0;

                }

              }, 2500);

            }();

            watch(
                () => email.value,

                (first, second) => {

                    console.log(email.value)

                }

            );
            
            /* Perhaps don't use this until time has passed to have data for a chart
            setTimeout(
                () => {

                    getChartData('RDX');

                },
                100
            ); */

            const runNews = function () {

                setTimeout(
                    () => {

                    },
                    5000
                );

            };
            runNews();

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            web3,
            openLogin,
            userInfo,
            email,
            openSwap,
            buyToken,
            buyTokens,
            mockBuyTokens,
            sellToken,
            sellTokens,
            raredexToken,
            getAllERC20Tokens,
            getPts,
            currentSlide,
            currentIndex,
            fiveYearChange,
            selectedSymbol,
            chartData,
            d3GraphContainer,
            symbols,
            getIcon,
            getRawIcon,
            gotoTab,
            gotoUrl,
            getChartData,
            showMetalModal,
            showSwapModal,
            metalContainer,
            pieContainer,
            roadmapContainer,
            roadmapContent,
            faqContainer,
            faqContent,
            homeContainer,
            homeContainerLeft,
            homeContainerRight,
            homeHeaderText,
            homeContentText,
            homeHeaderTextBlack,
            homeContentTextBlack,
            tokens,
            serverConfig};

    }
};

</script>
<style scoped>
.separado {
  width: 20px;
  height: 20px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
}
.carousel-item {
}
.rare-earth-technology {
  font-size: 5em;
  margin: 100px;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.simple-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  padding: 20px;
  color: #5b702c;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.normal-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  margin: 20px;
  padding: 20px;
  color: #5b702c;
  border-radius: 20px;
  background: linear-gradient(1deg,#e2e5db63,transparent);
}
.cursive-content-text {
  max-width: 1000px;
  font-size: 4vh;
  margin-left: auto;
  margin-right: auto;
}
.forty-cent {
  width: 40%;
  margin: 10px;
  align-items: center;
  display: flex;
}
.home-header-container {
  position: relative;
}
video.eq-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.1;
  width: 100%;
}
.home-mission-text-container {
  padding-top: 100px;
}
.home-carousel-container {
  min-height: 820px;
}
.metal-container-desktop {
}
.metal-container-mobile {
  display: flex;
  flex-direction: flex-column-reverse;
  width: 100%;
  height: 100%;
}
.metal-image {
}
img.metal-img {
  width: 100%;
  background-size: contain;
}
.metal-text-c {
  max-width: 500px;
}
.metal-text-container {
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
}
.metal-text {
  font-size: 1.1em;
  padding: 20px;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
h1.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 5em;
  margin: 0px;
  padding-top: 30px;
}
.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 2em;
  font-size: 6vh;
  margin: 0px;
}
h1 .home-header-text {
  color: #4d4d4d;
}
h2 .home-header-text {
  color: #4d4d4d;
}
.title-text {
  justify-content: center;
  display: flex;
  font-weight: normal;
  color: #4d4d4d;
}
.home-title-text {
  margin-left: -20px;
  font-weight: normal;
  color: #4d4d4d;
}
.home-header-subtext {
  margin-top: 50px;
  font-weight: normal;
  color: #4d4d4d;
  font-size: 1.1em;
}
.home-content-text-mobile {
  color: white;
  font-size: 1.2em;
  font-size: 4vh;
  margin: 0px 50px;
}
h1.home-header-text-mobile {
  margin: 50px;
  color: white;
  font-size: 3em;
  font-size: 10vh;
}
.home-content-text-black {
  color: #5f7237;
  font-size: 2em;
  font-size: 6vh;
  margin: 50px;
}
h1.home-header-text-black {
  margin: 50px;
  color: #000;
  font-size: 5em;
  font-size: 15vh;
}
.home-content-text-mobile-black {
  margin: 30px;
  color: #5f7237;
  font-size: 1.2em;
  font-size: 4vh;
}
h1.home-header-text-mobile-black {
  color: #000; 
  font-size: 3em;
  font-size: 10vh;
}
.home-platform-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img.large-platform-img {
  margin: 10px;
  width: 90%;
}
img.platform-img {
  margin: 10px;
  width: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.home-curve-container {
}
svg.home-mission-curve {
  position: absolute;
  background: transparent;
  left: 0;
  bottom: 0;
}
svg.home-curve {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 50% 70%,#7d5cff,rgba(255,92,244,.47000000000000003));
  left: 0;
}
.raredex-logo {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 10%;
}
.raredex-logo-img {
  width: 30%;
  max-width: 500px;
  min-width: 300px;
  margin-bottom: 50px;
}
.colorstroke {
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.governance-backdrop {
  background-size: auto;
  background-position: center;
  margin-left: 10px;
  margin-right: 10px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.good-link {
  color: #3783bb;
}
.home-roadmap-container {
  font-size: 1.1em;
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.home-roadmap-box-left-a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 10px solid #ced4c2;
  border-right: 10px solid #ced4c2;
  background-color: #f8faff;
}
.home-roadmap-box-right-a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-left-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-right-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-left: 10px solid #ced4c2;
  border-bottom: 10px solid #ced4c2;
  background-color: #f8faff;
}
.founders-container {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.compdeep-logo {
  cursor: pointer;
  width: 100%;
  height: 100px;
  background-image: url('../assets/compdeep-banner-plain.png');
  margin: 20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  max-width: 300px;
}
.home-footer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.voxels-container {
  position: relative;
  padding: 0;
  margin-top: 100px;
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(33,3%);
}
.voxels {
  background: #53b2fe;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  scale: 0;
  opacity: 0;
  animation: box 5s linear infinite;
}
@keyframes box {
  0%, 50%, 90% { scale: 1; opacity: 1 }
  25%, 75%, 100% { scale: 0; opacity: 0 }
}
.root-container {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.home-container-desktop {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}
.home-container-mobile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  max-width: 1600px;
}
.home-container-a-left-mobile {
  width: 100%;
}
.home-container-a-right-mobile {
  width: 100%;
}
.home-container-a-left-desktop {
  width: 60%;
}
.home-container-a-right-desktop {
  display: flex;
  align-items: center;
  width: 40%;
}
.home-container-a-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  min-width: 300px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.symbolicon {
  display: flex;
  width: 36px;
  height: 36px;
}
.home-container-a-price {
  background-color: none;
  text-align: left;
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
}
.home-container-metal-name {
  color: #505050;
  font-size: 0.8em;
}
.home-container-a-symbol {
  color: #505050;
  font-weight: bold;
  font-size: 0.6em;
  margin-left: 5px;
  margin-right: 5px;
}
.home-container-a-price {
  color: #505050;
  font-size: 0.8em;
  font-weight: bold;
}
.home-container-a-ytd-header {
  font-size: 0.8em;
  color: grey; 
  font-weight: bold;
  text-align: right;
}
.home-container-a-ytd {
  font-size: 0.8em;
  color: grey; 
  font-weight: bold;
}
.home-container-a-ytd-neg {
  font-size: 0.8em;
  color: #ff7cf5;
  font-weight: bold;
}
.home-container-a-ytd-pos {
  font-size: 0.8em;
  color: #46b6ff;
  font-weight: bold;
}
.home-container-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 70vh;
  margin: 5px;
  cursor: pointer;
}
.link-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.voxels-text-container {
  position: absolute;
  color: #232b3f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  top: 150px;
}
.buy-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.svg-arbitrum-logo {
  width: 96px;
  height: 96px;
  display: flex;
}
.svg-logo {
  display: flex;
}
.home-header-text-container {
  margin-right: 100px;
}
.home-register-container {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.bottom-register-container {
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.home-register-button {
  display: flex;
  margin: 10px;
  width: fit-content;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7cf5,#46b6ff);
}
.home-main-asset {
  min-width: 300px;
  width: 50%;
  height: 400px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/FrameToken.png");
}
.graph-container-desktop {
  height: 50vh;
  width: 50%;
  margin: 10px;
  padding: 10px;
}
.graph-container-mobile {
  height: 80vh;
  width: 100%;
  margin-left: -40px;
}
.pie-container-desktop {
  margin-top: -100px;
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.pie-container-mobile {
  margin-top: -200px;
  height: 500px;
  width: 300px;
}
.diamond-container {
  height: 500px;
  min-width: 400px;
  width: 50%;
}
.five-year-text {
  min-height: 300px;
  margin: 10px;
}
.five-year-percent-change {
  font-weight: bold;
  color: rgb(47, 178, 255);
}
.five-year-percent-change-text {
  color: #061d2b;
}
.appreciation-container {
  max-width: 500px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.slide-fade-enter-active {
    transition: all 1.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
.voxels-text {
  font-size: 9em;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.operations-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #7612b3;
}
.metals-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #40a5e7;
}
.vc-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #ea72e0;
}
.liquidity-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #e670dd;
}
.slice-text {
  margin: 10px;
  max-width: 500px;
}
.chart-key-container {
  margin-top: 70px;
}
/*
@keyframes rotateDiamond {
    from {
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }
    to {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}
*/
.telegram-link {
  display: flex;
  flex-direction: row;
  align-items:center;
  margin-right: 10px;
  margin:10px;
}
.telegram-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.telegram-icon {
  margin-right:10px;
  width: 48px;
  height: 48px;
}
.swap-text-box {
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #fff;
  box-shadow: 0 15px 20px 2px rgba(139,106,224,.33);
  margin-left: auto;
  margin-right: auto;
}
.swap-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.swap-text-more-info {
  width: 80%;
  margin-top: 15px;
  display: flex;
  transition: border 300ms;
  padding: 15px;
  border-radius: 20px;
  box-shadow: 0 15px 20px 2px rgba(139,106,224,.33);
}
.swap-text-more-info:hover {
  cursor: pointer;
}
.swap-text-info {
  width: fit-content;
  font-size: 1.2em;
  font-weight: bold;
  margin-right: 50px;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
.swap-text-info:hover {
  background: transparent;
  text-shadow: 0 0 4px #ff1e8ca8, 0 0 8px #ff1e8ca8, 0 0 16px #ff1e8ca8, 0 0 32px #fff, 0 0 48px #fff;
  -webkit-text-fill-color: white;
  cursor: pointer;
}
.swap-text {
  font-size: 1.2em;
  font-weight: bold;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.no-style-link {
  text-decoration: none;
  #color: #777;
}
.no-style-link:hover {
  cursor: pointer;
  text-decoration: underline;
  #color: #999;
}
.roadmap {
    position: relative;
    text-align: center;
    padding: 50px 20px;
    background: #f9f9f9;
}

.roadmap h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
}

.roadmap-container-desktop {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.roadmap-container-mobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
}

.roadmap-bubble {
    position: relative;
    min-width: 150px;
    min-height: 150px;
    border-radius: 50%;
    background: linear-gradient(338deg, #ff7cf5, #46b6ff);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.roadmap-bubble:hover {
    transform: scale(1.1);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.bubble-content {
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    z-index: 1;
}

.bubble-content::after {
    content: attr(data-quarter);
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0.9rem;
    color: rgba(255, 255, 255, 0.7);
}
.bubble-desktop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.bubble-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}			
.faq {
    position: relative;
    text-align: center;
    padding: 50px 20px;
    background: #f9f9f9;
}
.faq h2 {
    font-size: 2.5rem;
    margin-bottom: 30px;
    color: #333;
}

.faq h3 {
    font-size: 2.0rem;
    margin-bottom: 20px;
    color: #333;
}
.faq-header-text {

}
.faq-desktop {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
}
.faq-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:  flex-start;
}
.faq-container-desktop {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: left;
  align-items: flex-start;
  flex-direction: inherit;
}

.faq-container-mobile {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  flex-direction: inherit;
}
.faq-question {
    font-weight: normal;
}
.faq-answer {
    font-size: smaller;
    padding-bottom: 12px;
}
@keyframes rotateBubble {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes rotateBubbleReverse {
    0% {
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(0deg);
    }
}
.roadmap-text {
    margin: 10px;
    padding: 10px;
    max-width: 400px;
}

</style>

<style lang="scss" scoped>

  @for $i from 1 through 1000 {
    .voxels {
      &:nth-child(#{$i}) {
        animation-delay: calc(
          -10s * #{$i} * sin(#{$i})
        );
      }
    }
  }

  @keyframes m {
    0%, 50%, 90% { scale: 1; opacity: 1 }
    25%, 75%, 100% { scale: 0; opacity: 0 }
  }
</style>

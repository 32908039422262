<template>
    <head>
      <title>Raredex Terms of Use</title>
      <meta
        http-equiv="Content-Type"
        content="text/html; charset=UTF-8"
      >
      <meta
        name="generator"
        content="pdftohtml 0.36"
      >
    </head>
    <div class="terms-container">
    <h1 class="doc">
    </h1><br>
<h1>Terms and Conditions</h1>

<p>
    These terms of use (these “Terms”) govern the use of facilities provided by “Raredex” (“we” or “us”) on the Internet 
    (the “Community”), along with related services (collectively referred to as the “Services”). These Terms outline 
    the obligations and rights of Users (as defined in Article 1 below) and prospective Users of the Services. By using 
    the Services, you agree to these Terms.
</p>

<h2>Article 1: Definitions</h2>
<p>The following terms are defined as follows:</p>
<ul>
    <li><b>“User”:</b> A person who uses the Services.</li>
    <li><b>“User ID”:</b> An email address registered by a User to access the Services.</li>
    <li><b>“Password”:</b> A unique string set by a User linked to their User ID.</li>
    <li><b>“Smart Contracts”:</b> Self-executing contracts with terms directly written into code on the blockchain.</li>
    <li><b>“Registration Information”:</b> Any information provided during registration for Raredex Services.</li>
    <li><b>“Social Media User”:</b> A User interacting with Raredex-facilitated social media services.</li>
    <li><b>“Tokens”:</b> Smart Contract assets minted, transferred, or burned by Raredex Smart Contracts.</li>
</ul>

<h2>Article 2: Scope of Application</h2>
<ol>
    <li>These Terms set forth the conditions for using the Services, rights, and obligations between Users and us.</li>
    <li>Policies and rules related to the Services (“Use Rules”) published on our website form part of these Terms.</li>
    <li>In case of discrepancies between these Terms and the Use Rules, these Terms shall prevail unless otherwise specified.</li>
</ol>

<h2>Website & Social Media Usage</h2>
<ul>
    <li>You must be at least 18 years old to use our Services.</li>
    <li>You agree to provide accurate and complete information during account creation.</li>
    <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
    <li>You must comply with specific stipulations when interacting with our social media Services.</li>
</ul>

<h2>Smart Contract Interactions</h2>
<ul>
    <li>Interactions with our Smart Contracts are irreversible and immutable.</li>
    <li>You must use Smart Contracts only for their intended purposes.</li>
    <li>We are not liable for any losses due to user error or misunderstanding of Smart Contract functionality.</li>
</ul>

<h2>Article 3: Management of Password and User ID</h2>
<ol>
    <li>Users are responsible for securing their Passwords and User IDs.</li>
    <li>Users are fully liable for damages caused by misuse or third-party use of their credentials.</li>
    <li>Users must notify us immediately if they suspect unauthorized use of their account.</li>
    <li>We are not responsible for damages resulting from suspension of services due to account misuse.</li>
</ol>

<h2>Article 4: Management of Wallet</h2>
<p>
    Users must use a self-custody wallet to manage Private Keys required for Token transactions. We are not liable for 
    any damages resulting from the loss or compromise of such Private Keys.
</p>

<h2>Article 5: Fees and Royalties</h2>
<p>
    We may determine fees for using our Services. Such fees will be communicated separately.
</p>

<h2>Article 6: Intellectual Property Rights</h2>
<p>
    All content on our website, including text, graphics, logos, and software, is owned by Raredex (unless stated 
    otherwise) and protected by intellectual property laws.
</p>

<h2>Article 7: User Conduct</h2>
<ul>
    <li>You must not use the Services for illegal purposes.</li>
    <li>You must not attempt to interfere with the proper functioning of the Services.</li>
    <li>You must not impersonate any entity or misrepresent your affiliation.</li>
</ul>

<h2>Article 8: Liability Limitations</h2>
<ul>
    <li>We are not liable for any damages resulting from your use of our Services.</li>
    <li>We do not guarantee the accuracy or usefulness of information provided through our Services.</li>
</ul>

<h2>Article 9: Privacy</h2>
<p>
    Your use of our Services is governed by our Privacy Policy, incorporated by reference into these Terms.
</p>

<h2>Article 10: Modifications to Services</h2>
<p>
    We reserve the right to modify or discontinue any aspect of our Services without prior notice.
</p>

<h2>Article 11: Termination</h2>
<p>
    We may terminate or suspend your account without notice for violations of these Terms or other reasons.
</p>

<h2>Article 12: Changes to Terms</h2>
<p>
    We reserve the right to modify these Terms at any time. Continued use of our Services indicates acceptance of the updated Terms.
</p>

<h2>Article 13: Contact Information</h2>
<p>
    If you have questions about these Terms, contact us at <a href="mailto:legal@raredex.io">legal@raredex.io</a>.
</p>

<p><b>Last updated:</b> November 4, 2024</p>
<hr>
  </div>
</template>
<script>

import {inject, onMounted, reactive, ref, watch} from "vue";
import {translationStore} from "@/stores/translation";

export default {
    "name": "Terms",
    "components": {
    },
    "props": {
    },
    setup () {

        const serverConfig = inject("serverConfig"),
            translation = translationStore(),
            userInfo = inject("userInfo"),
            thisPDF = ref(""),
            docMain = ref(""),
            shinoViDocs = ref(""),
            isLang = function (lang) {

                console.log("translation.getLang()");
                console.log(translation.getLang());

                return translation.getLang().indexOf(lang);

            },
            showPDF = function (pdf) {

                showPDFModal.value = true;
                thisPDF.value = `${process.env.VUE_APP_SERVER_URI + pdf}#view=FitH`;

            },
            resizeIframe = function (obj) {

                obj.style.height = `${obj.value.contentWindow.document.documentElement.scrollHeight}px`;

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-desktop";

                } else if (serverConfig.view === "laptop") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-laptop";

                } else if (serverConfig.view === "tablet") {

                    shinoViDocs.value = "shinovi-docs-desktop";
                    docMain.value = "doc-main-tablet";

                } else if (serverConfig.view === "mobile") {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                } else {

                    shinoViDocs.value = "shinovi-docs-mobile";
                    docMain.value = "doc-main-mobile";

                }

            },
            showPDFModal = ref(false);

        onMounted(() => {

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

            updateView();

        });

        return {"localize": translation.localize,
            translation,
            isLang,
            resizeIframe,
            thisPDF,
            docMain,
            shinoViDocs,
            updateView,
            showPDFModal,
            showPDF};

    }
};

</script>
<style scoped>
.doc-main-desktop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-laptop {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1200px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-mobile {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  padding: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
.doc-main-tablet {
  display: flex; height: 100%; flex-direction: column; overflow: hidden;
  font-size: 0.9em;
  line-height: 30px;
  max-width: 1000px;
  margin: 10px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}
iframe {
  border: none;
}
h1.doc {
  margin-top: 100px;
  margin-bottom: 100px;
  line-height: 50px;
}
.terms-container {
  padding: 50px;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}
.shinovi-docs-desktop {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 100px;
  max-width: 1000px;
}
.shinovi-docs-mobile {
  flex-grow: 1; border: none; margin: 0; padding: 0;
  margin-top: 50px;
  max-width: 500px;
}
.footer-item {
  margin: 10px;
  cursor: pointer;
  color: #505050;
}
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            padding: 50px;
            max-width: 1600px;
            margin: auto;
            color: #333;
        }
        h1, h2 {
            color: #0056b3;
        }
        h1 {
            text-align: center;
        }
        ul {
            margin: 10px 0 20px 20px;
        }
        ul li {
            margin: 5px 0;
        }
        hr {
            margin: 40px 0;
            border: 0;
            border-top: 1px solid #ccc;
        }
        b {
            color: #000;
        }
</style>

